<template>
  <v-container>
    <perseu-card title="Modelos de Terapia">
      <template #title-right>
        <v-btn color="secondary" @click="newTemplate"> Novo modelo</v-btn>
      </template>
      <v-flex slot="content">
        <v-switch v-model="query.active" label="Ativos" />
        <v-data-table :items="templates" :headers="headers" class="elevation-1">
          <template #item.actions="props">
            <table-action-button
              @click="edit(props.item)"
              icon="edit"
              small
              text="Editar"
            />
            <table-action-button
              @click="activateOrDeactivate(props.item)"
              v-if="props.item.active"
              icon="delete"
              small
              text="Desativar"
            />
            <table-action-button
              @click="activateOrDeactivate(props.item)"
              v-else
              icon="check"
              small
              text="Ativar"
            />
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
  </v-container>
</template>

<script>
import { findAll, toggleActive } from "@/services/therapy-templates-service";
import { pagesNames } from "@/router/pages-names";

export default {
  name: "List",
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "Criado por", value: "createdBy" },
      { text: "Escola", value: "school" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    templates: [],
    query: {
      active: true,
    },
  }),
  mounted() {
    this.fetchAllTemplates();
  },
  watch: {
    query: {
      handler() {
        this.fetchAllTemplates();
      },
      deep: true,
    },
  },
  methods: {
    newTemplate() {
      this.$router.push({ name: pagesNames.THERAPY_TEMPLATES_FORM });
    },
    async fetchAllTemplates() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findAll(this.query);
        this.templates = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    edit({ id }) {
      this.$router.push({
        name: pagesNames.THERAPY_TEMPLATES_FORM,
        query: { id },
      });
    },
    async activateOrDeactivate({ id }) {
      try {
        this.$store.dispatch("loading/openDialog");
        await toggleActive(id);
        const index = this.templates.findIndex(
          (template) => template.id === id
        );
        console.log(index);
        this.templates.splice(index, 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped></style>
